<template>
  <div class="__timecard">
    <StampLogo class="mb-5" title="打刻修正"></StampLogo>
    <button 
      class="btn __outline rounded __punch __punched-no mt-5"
      @click="showTimeEditDialog('start')"
    >
      <div class="__btn-text">スタート</div>
      <span class="__punch-time align-items-center">
        <div class="__time">{{ time_start_hours }}</div>
        <div class="__separator">:</div>
        <div class="__time">{{ time_start_minutes }}</div>
      </span>
    </button>
    <button 
      class="btn __outline rounded __punch __punched-no mt-3"
      @click="showTimeEditDialog('stop')"
    >
      <div class="__btn-text">ストップ</div>
      <span class="__punch-time align-items-center">
        <div class="__time">{{ time_stop_hours }}</div>
        <div class="__separator">:</div>
        <div class="__time">{{ time_stop_minutes }}</div>
      </span>
    </button>
    <button 
      class="btn __outline rounded __punch __punched-no mt-3"
      @click="showTimeEditDialog('break')"
    >
      <div class="__btn-text">休憩</div>
      <span class="__punch-time align-items-center">
        <div class="__time">{{ basic_break_hours }}</div>
        <div class="__separator">:</div>
        <div class="__time">{{ basic_break_minutes }}</div>
      </span>
    </button>
    <div class="__time-breakdown mt-5">
      <div class="__item">勤務時間</div>
      <span class="__punch-time">
        <div class="__item __left">{{ working_hours }}</div>
        <div class="__separator __item">:</div>
        <div class="__item __right">{{ working_minutes }}</div>
      </span>
    </div>
    <div class="__time-breakdown mt-3">
      <div class="__item">休憩時間</div>
      <span class="__punch-time">
        <div class="__item __left">{{ basic_break_hours }}</div>
        <div class="__separator __item">:</div>
        <div class="__item __right">{{ basic_break_minutes }}</div>
      </span>
    </div>
    <div class="__time-breakdown mt-3">
      <div class="__item">残業時間</div>
      <span class="__punch-time">
        <div class="__item __left">{{ overtime_hours }}</div>
        <div class="__separator __item">:</div>
        <div class="__item __right">{{ overtime_minutes }}</div>
      </span>
    </div>
    <button v-if="timecard.approval_status === 3 || !timecard.approval_status" class="btn rounded __apply-btn mt-5" @click="updateTimecard">
      <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span v-else>申 請</span>
    </button>
    <button class="btn rounded __delete-btn mt-3" @click="deleteTimecard">
      <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span v-else>削 除</span>
    </button>
  </div>
  <div class="modal fade" id="time-edit">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body d-flex flex-column align-items-center">
          <StampLogo class="mb-5" title="打刻修正"></StampLogo>
          <div class="row justify-content-between w-100 mt-1">
            <button class="__edit-btn __dim" @click="setSelectedTime(regular_start_hours, regular_start_minutes)">
              {{ `${regular_start_hours}:${regular_start_minutes}` }}
            </button>
            <button class="__edit-btn __dim" @click="setSelectedTime(regular_stop_hours, regular_stop_minutes)">
              {{ `${regular_stop_hours}:${regular_stop_minutes}` }}
            </button>
          </div>
          <div class="mt-2">
            <span>{{ timePicker.label }}</span>
          </div>
          <div class="row align-items-center">
            <!-- <NumberPicker
              class="mt-3 mb-3"
              :max-value="24"
              :value="timePicker.hour"
              :key="timePicker.hour"
              @click="numberPickerType = 'hours'"
              @change="hourChange"
            ></NumberPicker> -->
            <select class="__time-selector" v-model="timePicker.hour">
              <option v-for="(hour) in hourValue" :key="hour" :value="hour">
                {{ hour.toString().padStart(2, '0') }}
              </option>
            </select>
            <div class="__time-separator">:</div>
            <select class="__time-selector" v-model="timePicker.minute">
              <option v-for="(minute) in minuteValue" :key="minute" :value="minute">
                {{ minute.toString().padStart(2, '0') }}
              </option>
            </select>
            <!-- <NumberPicker
              class="mt-3 mb-3"
              :max-value="60"
              :value="timePicker.minute"
              :key="timePicker.minute"
              @click="numberPickerType = 'minutes'"
              @change="minuteChange"
            ></NumberPicker> -->
          </div>
          <!-- <div class="row justify-content-between w-100 mt-1">
            <button class="__edit-btn" @click="setNumberPickerValue(1)">- 1 時間</button>
            <button class="__edit-btn" @click="setNumberPickerValue(2)">+ 1 時間</button>
          </div> -->
          <button class="btn rounded __delete-btn mt-4 mb-2" @click="timeConfirm">
            確  定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StampLogo from '../../components/StampLogo.vue';
// import NumberPicker from '../../components/NumberPicker.vue';
import $ from 'jquery'

export default {
  data () {
    return {
      isLoading: false,
      basic_break_hours_setting: null,
      timecard: {
        id: null,
        date: null,
        time_start: null,
        time_stop: null,
        basic_break_hours: null,
        overtime_hours: null,
        working_hours: null,
        regular_working_hours_start: null,
        regular_working_hours_stop: null,
        approval_status: null,
        staff_id: null,
      },
      timePicker: {
        hour: 0,
        minute: 0,
        pickedHour: null,
        pickedMinute: null,
        label: 'スタート',
      },
      timeType: null,
      numberPickerType: 'hours',
      hourValue: [],
      minuteValue: []
    }
  }, 
  components: { StampLogo },
  beforeMount() {    
    this.parseAndValidateTimecardParam();
    for (let i = 0; i <= 23; i++) {
      this.hourValue.push(i);
    }
    for (let i = 0; i <= 59; i++) {
      this.minuteValue.push(i);
    }
  },
  computed: {
    // Time start
    time_start_hours() {
      return this.timecard.time_start ? this.timecard.time_start.hours().toString().padStart(2, '0') : '--';
    },
    time_start_minutes() {
      return this.timecard.time_start ? this.timecard.time_start.minutes().toString().padStart(2, '0') : '--';
    },
    // Time stop
    time_stop_hours() {
      return this.timecard.time_stop ? this.timecard.time_stop.hours().toString().padStart(2, '0') : '--';
    },
    time_stop_minutes() {
      return this.timecard.time_stop ? this.timecard.time_stop.minutes().toString().padStart(2, '0') : '--';
    },
    // Time punched flag
    isPunchedTimeStart() {
      return this.timecard.time_start != null;
    },
    isPunchedTimeStop() {
      return this.timecard.time_stop != null;
    },
    // Working hours
    working_hours() {
      return this.timecard.working_hours ? this.timecard.working_hours.hours().toString().padStart(2, '0') : '00';
    },
    working_minutes() {
      return this.timecard.working_hours ? this.timecard.working_hours.minutes().toString().padStart(2, '0') : '00';
    },
    // Basic break hours
    basic_break_hours() {
      return this.timecard.basic_break_hours ? this.timecard.basic_break_hours.hours().toString().padStart(2, '0') : '00';
    },
    basic_break_minutes() {
      return this.timecard.basic_break_hours ? this.timecard.basic_break_hours.minutes().toString().padStart(2, '0') : '00';
    },
    // Overtime hours
    overtime_hours() {
      return this.timecard.overtime_hours ? this.timecard.overtime_hours.hours().toString().padStart(2, '0') : '00';
    },
    overtime_minutes() {
      return this.timecard.overtime_hours ? this.timecard.overtime_hours.minutes().toString().padStart(2, '0') : '00';
    },
    // Regular start hours
    regular_start_hours() {
      return this.timecard.regular_working_hours_start ? this.timecard.regular_working_hours_start.hours().toString().padStart(2, '0') : '00';
    },
    regular_start_minutes() {
      return this.timecard.regular_working_hours_start ? this.timecard.regular_working_hours_start.minutes().toString().padStart(2, '0') : '00';
    },
    // Regular stop hours
    regular_stop_hours() {
      return this.timecard.regular_working_hours_stop ? this.timecard.regular_working_hours_stop.hours().toString().padStart(2, '0') : '00';
    },
    regular_stop_minutes() {
      return this.timecard.regular_working_hours_stop ? this.timecard.regular_working_hours_stop.minutes().toString().padStart(2, '0') : '00';
    },
  },
  watch: {
    'timecard.time_start'() {
      this.calculateTimecard();
    },
    'timecard.time_stop'() {
      this.calculateTimecard();      
    },
    'timecard.basic_break_hours'() {
      this.calculateTimecard();
      
    }
  },
  methods: {
    setNumberPickerValue(status) {
      if (status === 1) {
        // Increment the value
        if (this.numberPickerType === 'hours') {
          this.timePicker.hour = Math.min(24, this.timePicker.hour - 1); // Limit hour to 24
        } else {
          this.timePicker.minute = Math.min(59, this.timePicker.minute - 1); // Limit minute to 59
        }
      } else {
        // Decrement the value
        if (this.numberPickerType === 'hours') {
          this.timePicker.hour = Math.max(0, this.timePicker.hour + 1); // Limit hour to 0
        } else {
          this.timePicker.minute = Math.max(0, this.timePicker.minute + 1); // Limit minute to 0
        }
      }
    },
    setSelectedTime(hour, minute) {      
      this.timePicker.hour = parseInt(hour);
      this.timePicker.minute = parseInt(minute);
    },
    timeConfirm() {
      $('#time-edit').modal('hide');
      switch (this.timeType) {
        case 'start':
        this.timecard.time_start = this.$moment(`${this.timecard.date} ${this.timePicker.hour ? this.timePicker.hour.toString().padStart(2, '0') : '00'}:${this.timePicker.minute ? this.timePicker.minute.toString().padStart(2, '0') : '00'}:00`);
          break;
        case 'stop':
        this.timecard.time_stop = this.$moment(`${this.timecard.date} ${this.timePicker.hour ? this.timePicker.hour.toString().padStart(2, '0') : '00'}:${this.timePicker.minute ? this.timePicker.minute.toString().padStart(2, '0') : '00'}:00`);
          break;
        case 'break':
        this.timecard.basic_break_hours = this.$moment(`${this.timecard.date} ${this.timePicker.hour ? this.timePicker.hour.toString().padStart(2, '0') : '00'}:${this.timePicker.minute ? this.timePicker.minute.toString().padStart(2, '0') : '00'}:00`);
          break;
      }
      this.timeType = null;
    },
    hourChange(val) {
      this.timePicker.pickedHour = val;
    },
    minuteChange(val) {
      this.timePicker.pickedMinute = val
    },
    showTimeEditDialog(type) {    
    // if (this.timecard.approval_status !== 3) return;
      this.timeType = type;

      switch (type) {
          case 'start':
              this.timePicker.hour = this.time_start_hours && this.time_start_hours !== '--' ? parseInt(this.time_start_hours) : 0;
              this.timePicker.minute = this.time_start_minutes && this.time_start_minutes !== '--' ? parseInt(this.time_start_minutes) : 0;  
              this.timePicker.label = 'スタート';        
              break;
          case 'stop':
              this.timePicker.hour = this.time_stop_hours && this.time_stop_hours !== '--' ? parseInt(this.time_stop_hours) : 0;
              this.timePicker.minute = this.time_stop_minutes && this.time_stop_minutes !== '--' ? parseInt(this.time_stop_minutes) : 0;  
              this.timePicker.label = 'ストップ';        
              break;
          case 'break':
              this.timePicker.hour = this.basic_break_hours && this.basic_break_hours !== '--' ? parseInt(this.basic_break_hours) : 0;
              this.timePicker.minute = this.basic_break_minutes && this.basic_break_minutes !== '--' ? parseInt(this.basic_break_minutes) : 0;      
              this.timePicker.label = '休憩';
              break;
      }
      
      $('#time-edit').modal();
      console.log(this.timePicker.hour + ':' + this.timePicker.minute );
    },
    parseAndValidateTimecardParam() {
      try {
        const timecard = JSON.parse(decodeURIComponent(this.$route.params.timecard));
        // const requiredProperties = [ 'approval_status', 'basic_break_hours', 'id', 'overtime_hours', 'shop_id', 'staff_id', 'time_start', 'time_stop', 'working_hours', 'regular_working_hours_start', 'regular_working_hours_stop' ];
        // const hasAllProperties = requiredProperties.every(prop => Object.hasOwn(timecard, prop));
        // if (hasAllProperties) {
        // console.log(timecard);
          this.setTimecard(timecard);
        // }
        //  else {
        //   this.$router.push({ name: 'List'});
        // }
      } catch (error) {
        // this.$router.push({ name: 'List'});
      }      
    },
    setTimecard(timecard) {
      this.timecard.date = timecard.time_start ? this.$moment(timecard.time_start).format('YYYY-MM-DD') : this.$moment(timecard.date).format('YYYY-MM-DD');
      this.timecard.id = timecard.id;
      this.timecard.staff_id = timecard.staff_id ? timecard.staff_id : null,
      this.timecard.time_start = timecard.time_start != null ? this.$moment(timecard.time_start) : null;
      this.timecard.time_stop = timecard.time_stop != null ? this.$moment(timecard.time_stop) : null;
      this.timecard.working_hours = this.timecard.time_start ? this.$moment(`${this.timecard.date} 00:00`) : null;
      if (this.isPunchedTimeStart && this.isPunchedTimeStop && this.timecard.working_hours == null) {
        this.timecard.working_hours = this.$moment(`${this.timecard.date} ${timecard.working_hours}:00`);
      }
      this.timecard.basic_break_hours = timecard.basic_break_hours != '00:00' ? this.$moment(`${this.timecard.date} ${timecard.basic_break_hours}`) : null;
      this.basic_break_hours_setting = timecard.basic_break_hours != '00:00' ? this.$moment(`${this.timecard.date} ${timecard.basic_break_hours}`) : null;
      this.timecard.overtime_hours = timecard.overtime_hours != null ? this.$moment(`${this.timecard.date} ${timecard.overtime_hours}:00`) : null;
      this.timecard.regular_working_hours_start = timecard.regular_working_hours_start != null ? this.$moment(`${this.timecard.date} ${timecard.regular_working_hours_start}`) : null;
      this.timecard.regular_working_hours_stop = timecard.regular_working_hours_stop != null ? this.$moment(`${this.timecard.date} ${timecard.regular_working_hours_stop}`) : null;
      this.timecard.approval_status = timecard.approval_status;
    },
    calculateTimecard() {
      // Calculate regular work hours      
      let regularWorkHoursDuration = this.$moment.duration(
        this.timecard.regular_working_hours_stop.diff(this.timecard.regular_working_hours_start)
      );
      // console.log('regularWorkHoursDuration', regularWorkHoursDuration);
      // Calculate work hours
      if (this.timecard.time_stop && this.timecard.time_stop.isBefore(this.timecard.time_start)) {
        this.timecard.time_stop = this.timecard.time_stop.add(1, 'days');       
      }
      if (this.timecard.time_stop) {

        let workedHours = this.$moment.duration(this.timecard.time_stop.diff(this.timecard.time_start));
        workedHours = workedHours.subtract(parseInt(this.basic_break_hours), 'hours').subtract(parseInt(this.basic_break_minutes), 'minutes');  
        this.timecard.working_hours = workedHours;
        // console.log('workedHours', this.timecard.working_hours.hours());

        // Calculate overtime hours
        let overTimeHoursDuration = this.$moment.duration(0); 

        if (workedHours.asMinutes() > regularWorkHoursDuration.asMinutes()) {
          overTimeHoursDuration = workedHours.clone().subtract(regularWorkHoursDuration);
        }

        this.timecard.overtime_hours = overTimeHoursDuration;
      }
      // console.log('overTimeHoursDuration', overTimeHoursDuration.hours(), overTimeHoursDuration.minutes());
      
      // let overTimeHoursDuration = this.$moment.duration(workedHours.subtract(regularWorkHoursDuration.hours(), 'hours').subtract(regularWorkHoursDuration.minutes(), 'minutes'));
      // this.timecard.overtime_hours = this.$moment(`${this.timecard.date} ${overTimeHoursDuration.hours().toString().padStart(2, '0')}:${overTimeHoursDuration.minutes().toString().padStart(2, '0')}:00`);
      // console.log('overTimeHoursDuration', overTimeHoursDuration);
      
      // console.log('workingHours', this.timecard.working_hours);
      // // console.log('basicWorkHours', basicWorkHours);
      // console.log('---------');

      
      
      
    },
    async updateTimecard() {
      try {
        const response = await this.$http.put(
          process.env.VUE_APP_API_PATH + `/staff/shop/${this.$store.getters.shop}/timecard/apply/${this.timecard.id}`,
          { 
            time_start: this.timecard.time_start.format('YYYY-MM-DD HH:mm:ss'),
            time_stop: this.timecard.time_stop.format('YYYY-MM-DD HH:mm:ss'),
            break_time: `${this.basic_break_hours}:${this.basic_break_minutes}`,
            staff_id: this.timecard.staff_id,
          }
        );
        if (response.data.success) {
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }  
    },
    async deleteTimecard() {
      try {
        const response = await this.$http.delete(process.env.VUE_APP_API_PATH + `/staff/shop/${this.$store.getters.shop}/timecard/${this.timecard.id}`);
        if (response.data.success) {
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }  
    },
  }
}
</script>

<style scoped lang="scss">
.__width {
  width: 320px;
}

.__time {
  font-size: 24px;
  font-weight: 500;
}

.__btn-text {
  width: 75px;
}

.__time-breakdown {
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.__delete-btn {
  width: 200px;
  background-color: #E49113;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.__apply-btn {
  width: 200px;
  background-color: #4075FF;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.__edit-btn {
  border: 1px solid #000;
  border-radius: 0px;
  outline: none;
  height: 40px;
  width: 110px;
  background-color: #fff;
  
  &.__dim {
    background-color: rgb(251, 250, 250);
    border: 1px solid rgb(185, 181, 181);
    color: rgb(120, 118, 118);
  }
}

.__time-separator {
  font-size: 26px;
  font-weight: bolder;
}

.rounded {
  border-radius: 50px !important;
}

.__time-selector {
  font-size: 20px !important; 
  margin: 10px;
  padding: 10px;
}
</style>