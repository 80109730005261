<template>
  <div class="__timecard">
    <TimecardLogo class="__logo-margin" title="勤怠入力"></TimecardLogo>
    <div class="row __time">
      <div class="__time">{{ hours }}</div>
      <label for="">:</label>
      <div class="__time">{{ minutes }}</div>
      <label for="">:</label>
      <div class="__time">{{ seconds }}</div>
    </div>
    <div class="__date">{{ now }}</div>
    <button 
      class="btn __outline rounded __punch mt-5" 
      :class="{ '__punched-no': !isPunchedTimeStart, '__punched-yes': isPunchedTimeStart }"
      @click="punchIn"
    >
      <span>スタート</span>
      <span class="__punch-time">
        <div>{{ time_start_hours }}</div>
        <div class="__separator">:</div>
        <div>{{ time_start_minutes }}</div>
      </span>
    </button>
    <button 
      class="btn __outline rounded __punch mt-3" 
      :class="{ '__punched-no': !isPunchedTimeStop, '__punched-yes': isPunchedTimeStop }"
      @click="punchOut"
    >
      <span>ストップ</span>
      <span class="__punch-time">
        <div>{{ time_stop_hours }}</div>
        <div class="__separator">:</div>
        <div>{{ time_stop_minutes }}</div>
      </span>
    </button>
    <div class="__time-breakdown mt-5">
      <div class="__item">勤務時間</div>
      <span class="__punch-time">
        <div class="__item __left">{{ working_hours }}</div>
        <div class="__separator __item">:</div>
        <div class="__item __right">{{ working_minutes }}</div>
      </span>
    </div>
    <div class="__time-breakdown mt-3">
      <div class="__item">休憩時間</div>
      <span class="__punch-time">
        <div class="__item __left">{{ basic_break_hours }}</div>
        <div class="__separator __item">:</div>
        <div class="__item __right">{{ basic_break_minutes }}</div>
      </span>
    </div>
    <div class="__time-breakdown mt-3">
      <div class="__item">残業時間</div>
      <span class="__punch-time">
        <div class="__item __left">{{ overtime_hours }}</div>
        <div class="__separator __item">:</div>
        <div class="__item __right">{{ overtime_minutes }}</div>
      </span>
    </div>
  </div>
</template>
<script>
import TimecardLogo from '../../components/TimecardLogo';

export default {
  data () {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
      now: this.$moment().format('YYYY/MM/DD'),
      timecard: {
        id: null,
        time_start: null,
        time_stop: null,
        basic_break_hours: null,
        overtime_hours: null,
        working_hours: null,
      },
      position: null,
      store_id: 0,
    }
  },
  components: { TimecardLogo },
  beforeMount() {
    this.clock();
    this.fetchTimecard();
    // Check if the Geolocation API is available
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.position = position.coords;
      });
    }
    this.store_id = this.$store.getters.shop;
  },
  computed: {
    // Time start
    time_start_hours() {
      return this.timecard.time_start ? this.$moment(this.timecard.time_start).hours().toString().padStart(2, '0') : '--';
    },
    time_start_minutes() {
      return this.timecard.time_start ? this.$moment(this.timecard.time_start).minutes().toString().padStart(2, '0') : '--';
    },
    // Time stop
    time_stop_hours() {
      return this.timecard.time_stop ? this.$moment(this.timecard.time_stop).hours().toString().padStart(2, '0') : '--';
    },
    time_stop_minutes() {
      return this.timecard.time_stop ? this.$moment(this.timecard.time_stop).minutes().toString().padStart(2, '0') : '--';
    },
    // Time punched flag
    isPunchedTimeStart() {
      return this.timecard.time_start != null;
    },
    isPunchedTimeStop() {
      return this.timecard.time_stop != null;
    },
    // Working hours
    working_hours() {
      return this.timecard.working_hours ? this.$moment(this.timecard.working_hours).hours().toString().padStart(2, '0') : '--';
    },
    working_minutes() {
      return this.timecard.working_hours ? this.$moment(this.timecard.working_hours).minutes().toString().padStart(2, '0') : '--';
    },
    // Basic break hours
    basic_break_hours() {
      return this.timecard.basic_break_hours ? this.$moment(this.timecard.basic_break_hours).hours().toString().padStart(2, '0') : '--';
    },
    basic_break_minutes() {
      return this.timecard.basic_break_hours ? this.$moment(this.timecard.basic_break_hours).minutes().toString().padStart(2, '0') : '--';
    },
    // Overtime hours
   overtime_hours() {
      return this.timecard.overtime_hours ? this.$moment(this.timecard.overtime_hours).hours().toString().padStart(2, '0') : '--';
    },
   overtime_minutes() {
      return this.timecard.overtime_hours ? this.$moment(this.timecard.overtime_hours).minutes().toString().padStart(2, '0') : '--';
    },
  },
  methods: {
    clock() {
      this.hours = this.$moment().hours().toString().padStart(2, '0');
      this.minutes = this.$moment().minutes().toString().padStart(2, '0');
      this.seconds = this.$moment().seconds().toString().padStart(2, '0');
      setTimeout(this.clock, 1000);
    },
    setTimecard(timecard) {
      this.timecard.id = timecard.id;
      this.timecard.time_start = timecard.time_start != null ? this.$moment(timecard.time_start) : null;
      this.timecard.time_stop = timecard.time_stop != null ? this.$moment(timecard.time_stop) : null;
      this.timecard.working_hours = timecard.working_hours != '00:00' ? this.$moment(`${this.$moment().format('YYYY-MM-DD')} ${timecard.working_hours}:00`) : null;
      if (this.isPunchedTimeStart && this.isPunchedTimeStop && this.timecard.working_hours == null) {
        this.timecard.working_hours = this.$moment(`${this.$moment().format('YYYY-MM-DD')} ${timecard.working_hours}:00`);
      }
      this.timecard.basic_break_hours = timecard.basic_break_hours != '00:00' ? this.$moment(`${this.$moment().format('YYYY-MM-DD')} ${timecard.basic_break_hours}`) : null;
      this.timecard.overtime_hours = timecard.overtime_hours != null ? this.$moment(`${this.$moment().format('YYYY-MM-DD')} ${timecard.overtime_hours}:00`) : null;
    },  
    fetchTimecard() {
      this.$http.get(process.env.VUE_APP_API_PATH + `/staff/shop/${this.$store.getters.shop}/timecard/${this.$moment().format('YYYY-MM-DD')}`)
      .then(response => {        
        if (response.data.success) {
          const data = response.data.data;          
          if (data.timecard) {
            this.setTimecard(data.timecard);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    punchIn() {
      if (this.isPunchedTimeStart) return;
      this.$http.post(process.env.VUE_APP_API_PATH + `/staff/shop/${this.store_id}/timecard/in`, {
        time_start: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
        latitude: this.position?.latitude,
        longitude: this.position?.longitude
      })
      .then(response => {
       if (response.data.success) {
          const data = response.data.data;          
          if (data.timecard) {
            this.setTimecard(data.timecard);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    punchOut() {
      if (!this.isPunchedTimeStart || this.isPunchedTimeStop) return;
      this.$http.put(process.env.VUE_APP_API_PATH + `/staff/shop/${this.store_id}/timecard/out`, {
        id: this.timecard.id,
        time_stop: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
        latitude: this.position?.latitude,
        longitude: this.position?.longitude
      })
      .then(response => {
       if (response.data.success) {
          const data = response.data.data;          
          if (data.timecard) {
            this.setTimecard(data.timecard);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
  }
}
</script>

<style>
.__time {

  div {
    width: 70px;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
  }
  label {
    font-size: 35px;
  }
}

.__date {
  font-size: 24px;
}

.__punch-time {
  display: flex;
  font-weight: bold;

  .__separator {
    width: 20px;
    text-align: center;
  }

  .__left {
    text-align: left;
    width: 15px;
  }

  .__right {
    text-align: right;
    width: 15px;
  }
}

.__time-breakdown {
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>