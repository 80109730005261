<template>
  <div class="login-wrapper">
    <div class="login-box">
      <div class="">
        <div class="card-header bg-success text-center">
          <a href="#" class="h2">みんなの勤怠 <img class="logo" src="../assets/logo.png" alt="logo"></a>
        </div>
        <div class="card-body">

          <form action="/" method="post">
            <div class="input-group mb-3">
              <input type="email" v-model="username" class="form-control" placeholder="メール" required>
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input :type="showPassword ? 'text' : 'password'" class="form-control" placeholder="パスワード" v-model="password" required>
              <div class="input-group-append">
                <div class="input-group-text" @click="togglePasswordVisibility">
                  <span class="icon-eye fas fa-eye"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-check float-right mb-3">
                  <input type="checkbox" class="form-check-input" v-model="remember">
                  <label class="form-check-label remember" for="remember">ログイン情報を保存する</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button type="button" class="btn btn-success btn-block" @click="login">
                  <span v-if="state" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span v-else>ログイン</span>
                </button>
              </div>
            </div>
          </form>

          <p class="mt-5 text-center">
            <a href="#" @click.prevent="redirectToForgotPassword">パスワードを忘れた方はこちら</a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6" v-if="company">
              <h5>Company</h5>
              <div class="form-check mb-3">
                <input class="form-check-input" type="radio" :id="company.id" :value="company.id" name="loginSelect" v-model="selectedCompany">
                <label class="form-check-label" :for="company.id">{{ company.company_name }}</label>
              </div>
            </div>
            <div class="col-md-6" v-if="shops">
              <h5>Shops</h5>
              <div class="form-check mb-3" v-for="(shop) in shops" :key="shop.id">
                <input class="form-check-input" type="radio" :id="'shop'+shop.id" :value="shop.id" name="loginSelect" v-model="selectedShop">
                <label class="form-check-label" :for="'shop'+shop.id">{{ shop.shop_name }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary" @click="proceed">ログインする</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade center" id="modal-reset-request" :data-backdrop="isRedirectFromMobile ? 'static' : 'non-static' ">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; top: 10px; right: 10px;  z-index: 1051;">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="mt-3">
            <h6>ご登録いただいたメールアドレスを⼊⼒してください。</h6>
            <h6>ログインパスワードを再設定いただくにあたり必要なURLをお送りいたします。</h6>
          </div>
          <div class="form-group mt-5">
            <input v-model="reset.email" type="text" class="form-control" placeholder="メールアドレス">
          </div>
        </div>
        <div class="modal-footer justify-content-around mb-3">
          <button type="button" class="btn btn-success btn-block" @click="passwordResetRequest">
            <span v-if="reset.loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-if="!reset.loading">送信</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade center" id="modal-reset-password" :data-backdrop="reset.success ? 'non-static' : 'static'">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; top: 10px; right: 10px;  z-index: 1051;">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="mt-3">
            <h6>パスワードを変更する</h6>
          </div>
          <form id="reset-from" class="needs-validation" novalidate>
            <div class="input-group mb-3 mt-5">
              <input id="reset-password" :type="reset.showPassword ? 'text' : 'password'" class="form-control" placeholder="パスワード" v-model="reset.password" @input="validatePassword">
              <div class="input-group-append">
                <div class="input-group-text" @click="toggleResetPassword">
                  <span class="reset-eye fas fa-eye"></span>
                </div>
              </div>
              <div class="invalid-feedback">
                大文字小文字記号(#?!@$%^&*- ）数字を含む8-16桁のみ利用可能
              </div>
            </div>
            <div class="input-group mb-3">
              <input id="reset-confirm-password" :type="reset.showConfirmPassword ? 'text' : 'password'" class="form-control" placeholder="新しいパスワードを再入力" v-model="reset.confirmPassword" @input="validateConfirmPassword">
              <div class="input-group-append">
                <div class="input-group-text" @click="toggleResetConfirmPassword">
                  <span class="reset-confirm-eye fas fa-eye"></span>
                </div>
              </div>
              <div class="invalid-feedback">
                パスワードが一致しません
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-around mb-3">
          <button type="button" class="btn btn-success btn-block" @click="updatePassword">
            <span v-if="state" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-else>パスワードの更新</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import $ from 'jquery'
  export default {
    name: "LoginPage",
    data () {
      return {
        data: [],
        username: null,
        password: null,
        showPassword: false,
        remember: false,
        shops: [],
        company: [],
        selectedShop: null,
        selectedCompany: null,
        validated: false,
        reset: {
          email: null,
          password: null,
          confirmPassword: null,
          showPassword: false,
          showConfirmPassword: false,
          loading: false,
          success: false,
        },
        state: false,
      }
    },
    created() {
      document.body.classList.add('login-page'); 
      document.body.classList.add('body-bg');
    },
    unmounted() {
      document.body.classList.remove('body-bg');
      document.body.classList.remove('login-page'); 
    },
    mounted() {
      document.body.classList.add('login-page'); 
      document.body.classList.add('body-bg');
      if (this.$route.query.redirect === 'mobile') {
        // Reset password redirect from mobile app 
        $('#modal-reset-request').modal();
      } else if (this.$route.query.token) {
        // Update password redirect from email
        $('#modal-reset-password').modal();
      }
    },
    computed: {
      filteredShop() {
        return (id) => {
          return this.shops.find(shop => shop.id === id).company_id;
        };
      },
      isRedirectFromMobile() {
        return this.$route.query.redirect === 'mobile';
      }
    },
    watch: {
      validated(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.dispatch('setAuth', this.data)
            .then(() => {
              switch (this.data.data.access) {
                case 1:
                  this.$router.push({ name: 'TopPage' });
                  break;
                case 2:
                    this.$router.push({ name: 'CompanyTopPage' });
                  break;
                case 3:
                  this.$router.push({ name: 'StoreTopPage' });
                  break;
                default:
                  break;
              }
            })
        }
      },
    },
    methods: {
      login () {
        if (this.state) return ;
        this.state = true;
        const data = {
          email: this.username,
          password: this.password,
          remember: this.remember,
        }
        this.$http.post(process.env.VUE_APP_API_PATH + '/auth/login', data)
        .then(response => {
          this.data = response.data;
          this.shops = this.data.data.shops;
          this.company = this.data.data.company;
          if (this.shops.length > 0) {
            if (!this.company && this.shops.length === 1) {
              this.data.data.shop_id = this.shops[0].id;
              this.data.data.company_id = this.data.data.shops[0].company_id;
              this.validated = true;
              return true;
            }
              $('#modal-default').modal();
          } else {
            if (this.company) {
              this.selectedCompany = this.company.id;
              this.data.data.company_id = this.company.id;
            }
            this.validated = true;
          }
          this.state = false
        })
        .catch(error => {
            if (error.response) {
              const response = error.response.data;
              if (response.errors) {
                this.$toast.error("Error: "+response.errors.email[0]);
              } else {
                this.$toast.error(response.message);
              }
            }
            this.state = false;
        });
      },
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
        this.toggleEyeIcon(document.querySelector('.icon-eye'), this.showPassword);
      },
      toggleEyeIcon(iconEl, show) {
        if (show) {
          iconEl.classList.remove('fas', 'fa-eye');
          iconEl.classList.add('fas', 'fa-eye-slash');
        } else {
          iconEl.classList.remove('fas', 'fa-eye-slash');
          iconEl.classList.add('fas', 'fa-eye');
        }
      },
      redirectToForgotPassword() {
        // this.$router.push({ name: 'ForgotPassword' });
        $('#modal-reset-request').modal();
      },
      proceed () {
        $('#modal-default').modal('hide');
        if (this.selectedCompany) {
          this.data.data.company_id = this.selectedCompany;
        }
        if (this.selectedShop) {
          this.data.data.access = 3;
          this.data.data.shop_id = this.selectedShop;
          this.data.data.company_id = this.filteredShop(this.selectedShop);
        }
        this.validated = true;
      },
      toggleResetPassword() {
        this.reset.showPassword = !this.reset.showPassword;
        this.toggleEyeIcon(document.querySelector('.reset-eye'), this.reset.showPassword);
      },
      toggleResetConfirmPassword() {
        this.reset.showConfirmPassword = !this.reset.showConfirmPassword;
        this.toggleEyeIcon(document.querySelector('.reset-confirm-eye'), this.reset.showConfirmPassword);
      },
      passwordResetRequest() {
        if (this.reset.loading) return;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!this.reset.email) {
          this.$toast.error("メールアドレスを入力してください。");
          return;
        }
        if (!emailRegex.test(this.reset.email)) {
          this.$toast.error("メール形式が正しくありません。");
          return;
        }
        this.reset.loading = true;
        this.$http.post(process.env.VUE_APP_API_PATH + '/auth/reset-password', { email: this.reset.email })
        .then(() => {
          this.$toast.success("リクエストが送信されました︕");
          this.reset.email = null;
        })
        .catch(error => {
            if (error.response) {
              const response = error.response.data;
              if (response.errors) {
                this.$toast.error(response.errors.email[0]);
              } else {
                this.$toast.error(response.message);
              }
            }
        }).finally(() => {
          this.reset.loading = false;
        })
      },
      validatePassword() {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#?!@$%^&:*-])[A-Za-z\d#?!@$%^&:*-]{8,16}$/;
        if (!passwordRegex.test(this.reset.password)) {
          $("#reset-password").addClass("is-invalid");
          return false;
        } else {
          $("#reset-password").removeClass("is-invalid");
          return true;
        }
      },
      validateConfirmPassword() {
        if (this.reset.confirmPassword !== this.reset.password) {
          $("#reset-confirm-password").addClass("is-invalid");
          return false;
        } else {
          $("#reset-confirm-password").removeClass("is-invalid");
          return true;
        }
      },
      updatePassword() {
        this.reset.success = true;
        if (!this.validatePassword() || !this.validateConfirmPassword() || this.reset.loading) return;
        this.reset.loading = true;
        this.$http.post(process.env.VUE_APP_API_PATH + '/auth/update-password', {
          token: this.$route.query.token,
          password: this.reset.password
        })
        .then(() => {
          this.$toast.success("パスワードは変更されました。");
          this.reset.success = true;
          this.reset.password = null;
          this.reset.confirmPassword = null;
        })
        .catch(() => {
          this.$toast.error("パスワード再設定用URLの有効期限が切れています。");
        }).finally(() => {
          this.reset.loading = false;
        })
      }
    }
  };
</script>

<style scoped>
  .remember {
    font-size: 10px;
    line-height: 16px;
    vertical-align: middle;
  }

  input[type="radio"] {
    scale: 1.5;
  }

  .logo {
    height: 45px;
    border: 3px solid #FFF;
    border-radius: 10px;
  }
</style>